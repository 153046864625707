import { Grid, Typography } from "@mui/material";
import Toast from "../components/Toast";
import Spacebox from "../components/styles/Spacebox";
import CustomButton from "../components/styles/Custombutton";
import Testimonies from "../components/Testimonies";
import { useState } from "react";
import supabase from "../config/supabaseClient";
import {v1 as uuidv1} from 'uuid';
import cookies from "../utilities/Cookies";
import { useNavigate } from "react-router-dom";
import { grey } from "@mui/material/colors";

const Signup = () => {
    document.querySelector('title').innerHTML = 'Create An Account | Azixbot';

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [usdtAdd, setUsdtAdd] = useState('')

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');


    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    
    const signup = async () => {
        setIsLoading(true);
        
        if(firstname === '' || lastname === '' || password.length < 8 || !email.includes('@') || usdtAdd === '') {
            setToastMsg('Password must be over 8 characters long');
            setSeverity('error')
            setOpen(true);
            setIsLoading(false);
            return
        }

        //check if email exists
        const {data, error} = await supabase.from('users')
        .select()
        .eq('email', email)
        .single()

        if(data) {
            //email exists
            setToastMsg('Email belongs to an existing user');
            setSeverity('error')
            setOpen(true);
            setIsLoading(false);
        }else if(error.code === "PGRST116") {
            // email doesn't exist
            const {data: data2, error: err2} = await supabase.from('users')
            .insert([{
                fullname: firstname +" " + lastname, 
                email, password,
                id: uuidv1(),
                timestamp: Date.now(),
                balance: 0,
                address: usdtAdd,
                type: 'free'
            }]).select()
    
            if(err2) {
                setToastMsg('An error occcured please try again');
                setSeverity('error')
                setOpen(true);
                setIsLoading(false);
                return
            }
    
            if(data2) {
                cookies.setCookies('user', JSON.stringify(data2[0]), 0.5);
                setToastMsg('Account created successfully');
                setSeverity('success')
                setOpen(true);
                setIsLoading(false);
                setTimeout(() => {
                    navigate('/chat');
                }, 4000);
            }
            
        }else {
            setToastMsg('Unexpected Error, try again');
            setSeverity('error')
            setOpen(true);
            setIsLoading(false);
        }

    }


    return (
        <div className="signup">
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={5} className="dark-theme" sx={{minheight: '100vh'}}>
                    <Spacebox padding='30px'>
                        <div className="logo">
                            <img src="/logo-white.png" alt="azixbot" style={{ width: '100px' }} />
                        </div>
                        <Spacebox padding='10px' />
                        <Spacebox padding='30px'>
                            <div className="seventy-percent" style={{ margin: 'auto' }}>
                                <Typography variant="h5">
                                    Get Started
                                </Typography>
                                <Typography variant="body2" color={grey[500]}>
                                    Create an account by filling out the form below
                                </Typography>
                                <Spacebox padding="30px" />
                                <small>Firstname</small>
                                <Spacebox padding='5px' />
                                <input type="text" onChange={(e) => setFirstname(e.target.value)} value={firstname} />
                                <Spacebox padding="10px" />
                                <small>Lastname</small>
                                <Spacebox padding='5px' />
                                <input type="text" onChange={(e) => setLastname(e.target.value)} value={lastname} />
                                <Spacebox padding="10px" />
                                <small>USDT address</small>
                                <Spacebox padding='5px' />
                                <input type="email" onChange={(e) => setUsdtAdd(e.target.value)} value={usdtAdd} />
                                <Spacebox padding="2px" />
                                <small style={{color: 'GrayText'}}>USDT address should be on Ethereum(ERC20) network</small>
                                <Spacebox padding="10px" />
                                <small>Email</small>
                                <Spacebox padding='5px' />
                                <input type="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                                <Spacebox padding="10px" />
                                <small>Password</small>
                                <Spacebox padding='5px' />
                                <input type="password" onChange={(e) => setPassword(e.target.value)} value={password} />
                                <Spacebox padding="20px" />
                                <CustomButton backgroundColor='var(--primary)' color='white' borderRadius='5px' padding='10px 0px' className='fullwidth' handleClick={signup}>
                                    {isLoading ? 'Authenticating...' : 'Sign Up'}
                                </CustomButton>
                            </div>
                        </Spacebox>
                    </Spacebox>
                </Grid>
                <Grid item lg={7} className="full hide-on-med-and-down">
                    <div className="flex align-center full justify-center" style={{width: '70%', margin: 'auto'}}>
                        <Testimonies index={2}/>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}
 
export default Signup;