import Spacebox from "./styles/Spacebox";

const Header = () => {
    
    return (
        <div className="header">
            <div>
                <Spacebox padding='20px'>
                    <div className="flex">
                        <Spacebox padding="20px" className="hide-on-med-and-down" />
                        <div className="logo">
                            <img src="/logo-white.png" alt="azixbot" style={{ width: 200 }} />
                        </div>
                    </div>
                </Spacebox>
            </div>
        </div>
    );
}

export default Header;