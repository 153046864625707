// import { Typography } from "@mui/material";
import Messages from "../components/Messages";
import OnAuth from "../components/onAuth";

const Home = () => {
    document.querySelector('title').innerHTML = 'Azixbot';
    return (
        <OnAuth>
            <div className="chatpage dark-theme border20 hide-scrollbar suitable-width" style={{ minHeight: '100%', maxHeight: '100%', overflowY: 'scroll', padding: '20px' }}>
                <Messages />
            </div>
        </OnAuth>
    );
}

export default Home;