import { useEffect } from "react";
import cookies from "../utilities/Cookies";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Spacebox from "./styles/Spacebox";
import Header from "./Header";

const OnAuth = ({ children }) => {

    const navigate = useNavigate();

    useEffect(() => {
        const user = cookies.getCookies('user');
        if (!user) {
            navigate('/login');
        }
        //eslint-disable-next-line
    }, [children])
    return (
        <div className="authenticate-layout full" style={{maxHeight: '100vh'}}>
            <Header />
            <div className="flex" style={{maxHeight: '85%', minHeight: '85%'}}>
                <Sidebar />
                <Spacebox padding='5px' className="hide-on-large-only"/>
                <Spacebox padding='10px' className="hide-on-med-and-down" />
                {children}
                <Spacebox padding='20px' className="hide-on-med-and-down"/>
                <Spacebox padding='5px' className="hide-on-large-only"/>
            </div>
        </div>
    );
}

export default OnAuth;