// eslint-disable-next-line
import { Grid, Typography } from "@mui/material";
// eslint-disable-next-line
import supabase from "../config/supabaseClient";
// eslint-disable-next-line
import { v1 as uuidv1 } from 'uuid'
import Spacebox from "../components/styles/Spacebox";
import { grey } from "@mui/material/colors";
import CustomButton from "../components/styles/Custombutton";
import { useState } from "react";
import Toast from "../components/Toast";
import { useNavigate } from "react-router-dom";
import cookies from "../utilities/Cookies"
import Testimonies from "../components/Testimonies";

const Login = () => {
    document.querySelector('title').innerHTML = 'Login | Azixbot';

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('msg');



    const login = async () => {

        setIsLoading(true);

        if (email === '' || password.length < 8) {
            setToastMsg('Password must be over 8 characters long');
            setSeverity('error')
            setOpen(true);
            setIsLoading(false);
            return
        }

        // check that email exists
        const { data, error } = await supabase.from('users')
            .select()
            .eq('email', email)
            .single();
            

        if (error) {
            setToastMsg('Email does not exist');
            setSeverity('error')
            setOpen(true);
            console.log(error);
            setIsLoading(false);
            return
        }

        if (data) {
            if (data.password === password) {
                setOutlines(data)

            } else {
                setToastMsg('Incorrect password');
                setSeverity('error')
                setOpen(true);
                setIsLoading(false);
                return
            }
        }


    }

    const getMonthInWord = (month) => {
        switch(month) {
            case 0:
                return 'Jan'
            case 1:
                return 'Feb'
            case 2:
                return 'Mar'
            case 3:
                return 'Apr'
            case 4:
                return 'May'
            case 5:
                return 'Jun'
            case 6:
                return 'Jul'
            case 7:
                return 'Aug'
            case 8:
                return 'Sep'
            case 9:
                return 'Oct'
            case 10:
                return 'Nov'
            case 11:
                return 'Dec'
            default:
                return 'NAM'
        }
    }

    const setOutlines = async (data) => {
        let d = new Date();
        let today_ = `${d.getDate()}-${getMonthInWord(d.getMonth())}-${d.getFullYear()}`;

        const {data: data2, error} = await supabase.from('transactions')
        .select()
        .eq('email', data.email)
        .eq('active', true)

        if(error) {
            setToastMsg('An error occured');
            setSeverity('error');
            setOpen(true)
            setIsLoading(false);
            return
        }

        if(data2) {
            // check is data2 has value
            if(data2.length > 0) {
                console.log('Has value')
                const days_outline = JSON.parse(data2[0].days_outline)
                let outline_index_found = 0;
                const day_outline = days_outline.filter((do_, index) => {
                    if(do_.day === today_) {
                        outline_index_found = index;
                        return do_.day === today_
                    }else {
                        return []
                    }
                })
                if(day_outline.length > 0) {
                    console.log('Found a matching outline day')
                    const percentage = day_outline[0].percentage;
                    const amount_ = percentage * data2[0].amount;
                    const unique_outline_id = parseInt(data2[0].id + "" + outline_index_found)
                    const {data: data3, error: error3} = await supabase.from('outlines')
                    .insert([{
                        id: unique_outline_id,
                        amount: amount_,
                        percentage: percentage,
                        date: day_outline[0].day,
                        timestamp: Date.now(),
                        transaction_id: data2[0].id,
                        email: data.email
                    }]).select()

                    // console.log({
                    //     id: unique_outline_id,
                    //     amount: amount_,
                    //     percentage: percentage,
                    //     date: day_outline[0].day,
                    //     timestamp: Date.now(),
                    //     transaction_id: data2[0].id,
                    //     email: data.email
                    // })

                    if(error3) {
                        if(error3.details.includes('already exist')) {
                            setIsLoading(false);
                            setToastMsg('Login Successful');
                            setSeverity('success');
                            setOpen(true);
                            cookies.setCookies('user', JSON.stringify(data), 0.5);
                            setTimeout(() => {
                                navigate('/chat', { replace: true });
                            }, 4000);
                        }else {
                            setToastMsg(error3.message);
                            setSeverity('error');
                            setOpen(true)
                            setIsLoading(false);
                            return
                        }
                    }

                    if(data3) {
                        setIsLoading(false);
                        setToastMsg('Login Successful');
                        setSeverity('success');
                        setOpen(true);
                        cookies.setCookies('user', JSON.stringify(data), 0.5);
                        setTimeout(() => {
                            navigate('/chat', { replace: true });
                        }, 4000);
                    }
                }else {
                    console.log('No matching outline day found')
                    setIsLoading(false);
                    setToastMsg('Login Successful');
                    setSeverity('success');
                    setOpen(true);
                    cookies.setCookies('user', JSON.stringify(data), 0.5);
                    setTimeout(() => {
                        navigate('/chat', { replace: true });
                    }, 4000);
                }
            }else {
                console.log('data2 does not have value')
                setIsLoading(false);
                setToastMsg('Login Successful');
                setSeverity('success');
                setOpen(true);
                cookies.setCookies('user', JSON.stringify(data), 0.5);
                setTimeout(() => {
                    navigate('/chat', { replace: true });
                }, 4000);
            }
        }


    }



    return (
        <div className="login">
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={5} className="dark-theme full">
                    <Spacebox padding='30px'>
                        <div className="logo">
                            <img src="/logo-white.png" alt="azixbot" style={{ width: '100px' }} />
                        </div>
                        <Spacebox padding='10px' />
                        <Spacebox padding='30px'>
                            <div className="seventy-percent" style={{ margin: 'auto' }}>
                                <Typography variant="h5">
                                    Welcome back
                                </Typography>
                                <Typography variant="body2" color={grey[500]}>
                                    Log into your account
                                </Typography>
                                <Spacebox padding="30px" />
                                <small>Email</small>
                                <Spacebox padding='5px' />
                                <input type="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                                <Spacebox padding="10px" />
                                <small>Password</small>
                                <Spacebox padding='5px' />
                                <input type="password" onChange={(e) => setPassword(e.target.value)} value={password} />
                                <Spacebox padding="20px" />
                                <CustomButton backgroundColor='var(--primary)' color='white' borderRadius='5px' padding='10px 0px' className='fullwidth' handleClick={login}>
                                    {isLoading ? 'Authenticating...' : 'Sign In'}
                                </CustomButton>
                            </div>
                        </Spacebox>
                    </Spacebox>
                </Grid>
                <Grid item lg={7} className="full hide-on-med-and-down">
                    <div className="flex align-center full justify-center" style={{width: '70%', margin: 'auto'}}>
                        <Testimonies index={0}/>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default Login;