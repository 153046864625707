import { Avatar, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import OnAuth from "../components/onAuth";
import Spacebox from "../components/styles/Spacebox";
import { useState } from "react";
import cookies from "../utilities/Cookies";
import Masonry from "react-masonry-css";
import { CheckBoxOutlined, Clear, CopyAllOutlined, SwapHoriz, TrendingUp } from "@mui/icons-material";
import CustomButton from "../components/styles/Custombutton";
import Toast from "../components/Toast";
import useFetch from '../hooks/useFetch';
import supabase from "../config/supabaseClient";
import { useNavigate } from "react-router-dom";

const Wallet = () => {
    document.querySelector('title').innerHTML = 'Wallet | Azixbot';

    const { data: service_fee } = useFetch(process.env.REACT_APP_SERVER_URL + 'gas-fee');

    const navigate = useNavigate()

    const wallets = [
        {
            wallet: 'Coinbase',
            address: '0x0b5a8d07f63830DB55c0Ca8e5b0323f03aF39EE1'
        },
        {
            wallet: 'Atomic',
            address: '0x66837c9A8B48e6B54148Db2eEFA82515DB08f01A'
        },
        {
            wallet: 'Trustwallet',
            address: '0x8A405Ff16a88139C3F60AbB49c83744f11c406A4'
        }
    ];

    const t_address = '0x947Ce3c42434C3461ed42923BB28071B7B948eDF';
    const [copyText, setCopyText] = useState('Copy to clipboard');

    const [amount, setAmount] = useState(1000);
    const [openPopup, setOpenPopup] = useState(false);
    const [depositAmount, setDepositAmount] = useState(1000);
    const [openDepositPopup, setOpenDepositPopup] = useState(false);

    const [loading, setLoading] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);


    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('msg');

    let stringify_user = cookies.getCookies('user')
    const user = stringify_user ? JSON.parse(stringify_user) : null;

    const breakpoints = {
        default: 2,
        1100: 2,
        700: 1
    }

    const deposit = async () => {
        setLoading(true);
        // check if user has any active transactions (on going investment)
        const result = await checkForActiveTransactions();
        
        console.log(result);
        
        if (result === true) {
            setSeverity('error');
            setToastMsg('You have an active Investment');
            setOpen(true);
            setLoading(false);
            return
        } else if (result === false) {
            // add transaction to db
            const { data, error } = await supabase.from('transactions')
                .insert([{
                    id: Date.now(),
                    timestamp: Date.now(),
                    email: user.email,
                    amount: depositAmount,
                    deposit: true,
                    status: 'pending', //pending, successful, failed
                    active: false
                }])
                .select()

            if (error) {
                setToastMsg('An error occured, please try again');
                setSeverity('error');
                setOpen(true);
                setLoading(false);
                console.log(error);
                return
            }

            if (data) {

                fetch(process.env.REACT_APP_SERVER_URL + 'deposit', {
                    method: 'POST',
                    mode: 'cors',
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Origin': 'http://localhost:3000' },
                    body: JSON.stringify({ user: user, amount: depositAmount, transactionID: data[0].id })
                }).then(res => res.json())
                    .then(res => {
                        if (res.success) {
                            setToastMsg('Account funding in progress');
                            setSeverity('success');
                            setOpen(true);
                            setOpenDepositPopup(false);
                            setLoading(false);
                            setShowSuccessMessage(true);
                        } else {
                            setToastMsg(res.msg);
                            setSeverity('error');
                            setOpen(true);
                            setOpenDepositPopup(false);
                            setLoading(false);
                            console.log(res.error);
                        }
                    }).catch(err => {
                        setToastMsg('An error occurred');
                        setSeverity('error');
                        setOpen(true);
                        setOpenDepositPopup(false);
                        setLoading(false);
                        console.log(err);
                    })
            }
        } else if (result === 'reload') {
            setSeverity('error');
            setToastMsg('An error occured, refresh page and try again');
            setOpen(true);
            setLoading(false);
            return
        } else if (result === 'More than 3 completed transaction') {
            setSeverity('error');
            setToastMsg('You have exceeded your free plan limit');
            setOpen(true);
            setLoading(false);
            setTimeout(() => {
                navigate('/payment-plan');
            }, 4000);
            return
        }
    }

    const checkForActiveTransactions = async () => {
        const { data: data1, error: error1 } = await supabase.from('transactions')
            .select()
            .eq('active', true)

        if (error1) {
            setSeverity('error');
            setToastMsg('Check incomplete');
            setOpen(true);
            return 'reload'
        }

        if (data1) {
            if (data1.length > 0) {
                return true
            } else {
                // TODO: create more payment plans instances
                //no active transactions - now check for completed transactions
                let completedTransactions = data1.filter(tran => tran.completed === true)
                if (completedTransactions.length >= 3 && user.type === 'free') {
                    return 'More than 3 completed transaction'
                }else{
                    return false
                }
            }
        }
    }

    const withdraw = () => {
        setLoading(true);

        const condition = !service_fee ? parseFloat(user.balance) <= parseFloat(amount) : parseFloat(user.balance) <= (parseFloat(amount) + (parseFloat(amount) * parseFloat(service_fee)));
        if (condition) {
            setSeverity('error');
            setToastMsg('Insufficient Funds');
            setOpen(true);
            console.log(parseInt(user.balance))
            console.log(amount + (amount * service_fee))
            setLoading(false);
            return
        }


        fetch(process.env.REACT_APP_SERVER_URL + 'sendmail', {
            method: 'POST',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Origin': 'http://localhost:3000' },
            body: JSON.stringify({ user: user, amount: amount })
        }).then(res => res.json())
            .then(async (res) => {
                if (res.success) {
                    // add this transaction to database
                    const { data, error } = await supabase.from('transactions')
                        .insert([{
                            id: Date.now(),
                            timestamp: Date.now(),
                            email: user.email,
                            amount: amount,
                            deposit: false,
                            status: 'pending',
                            active: false //pending, successful, failed
                        }])
                        .select()
                    if (error) {
                        setSeverity('error');
                        setToastMsg('Unexpected error, try again');
                        setOpen(true);
                        setLoading(false);
                        setOpenPopup(false);
                    }

                    if (data) {
                        setSeverity('success');
                        setToastMsg('Withdrwal Request is being processed');
                        setOpen(true);
                        setLoading(false);
                        setOpenPopup(false);
                    }
                } else {
                    setSeverity('error');
                    setToastMsg(res.msg);
                    setOpen(true);
                    setLoading(false);
                    setOpenPopup(false);
                    console.log(res.error);
                }
            }).catch(err => {
                setSeverity('error');
                setToastMsg('An error occured');
                setOpen(true);
                console.log(err);
                setLoading(false);
                setOpenPopup(false);
            })
    }

    const copyAddress = () => {
        if (service_fee) {
            window.navigator.clipboard.writeText(wallets[1].address)
            setCopyText('Copied')
        } else {
            window.navigator.clipboard.writeText(t_address)
            setCopyText('Copied')
        }
    }


    return (
        <OnAuth>
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <div className="walletpage dark-theme border20 hide-scrollbar suitable-width" style={{ minHeight: '100%', maxHeight: '100%', overflowY: 'scroll', padding: '20px' }}>
                {user && <div><Grid container spacing={5}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <Spacebox>
                            <Spacebox padding="30px" className="hide-on-med-and-down" />
                            <div className="flex align-center">
                                <Spacebox padding="30px" className="hide-on-med-and-down" />
                                <Avatar src="assets/Mohaka.png" alt="profile" sx={{ width: 50, height: 50, backgroundColor: 'var(--secondary)', border: '5px solid var(--secondary)' }} className="hide-on-large-only" />
                                <Avatar src="assets/Mohaka.png" alt="profile" sx={{ width: 150, height: 150, backgroundColor: 'var(--secondary)', border: '5px solid var(--secondary)' }} className="hide-on-med-and-down" />
                                <Spacebox padding="10px" />
                                <div className="text-content">
                                    <Typography variant="h4" className="bold hide-on-med-and-down">
                                        {user.fullname}
                                    </Typography>
                                    <Typography variant="h6" className="bold hide-on-large-only">
                                        {user.fullname}
                                    </Typography>
                                    <Typography variant="h6" className="hide-on-med-and-down">
                                        {user.email}
                                    </Typography>
                                    <Typography variant="body2" className="hide-on-large-only">
                                        {user.email}
                                    </Typography>
                                </div>
                            </div>
                        </Spacebox>
                    </Grid>
                    <Grid item lg={6} className="hide-on-med-and-down">

                    </Grid>
                </Grid>
                    <Spacebox padding="10px" />
                    {showSuccessMessage && <div className="content-box" style={{ backgroundColor: '#00e6760a', border: '2px solid #00e676' }}>
                        <Typography variant="body2" sx={{ color: 'var(--primary)' }}>
                            Your transaction is pending and upon verification your investment of {depositAmount} will proceed. More information about your investment can be found on the analytics page
                        </Typography>
                    </div>}
                    <Spacebox padding="10px" />
                    <Masonry
                        breakpointCols={breakpoints}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                    >
                        <div className="content-box" style={{ backgroundColor: 'black' }}>
                            <div>
                                <Typography variant="h6" sx={{ letterSpacing: '3px' }} className="hide-on-med-and-down">
                                    WALLET BALANCE
                                </Typography>
                                <Typography variant="body1" sx={{ letterSpacing: '3px' }} className="hide-on-large-only">
                                    WALLET BALANCE
                                </Typography>
                                <Typography variant="h3" style={{ color: 'var(--primary' }} className="bold">
                                    {(user.balance).toLocaleString()} USDT
                                </Typography>
                            </div>
                        </div>
                        <div className="content-box" style={{ backgroundColor: 'black' }}>
                            <TrendingUp sx={{ width: 50, height: 50 }} />
                            <Typography variant="h6" sx={{ letterSpacing: '3px' }}>
                                START AN INVESTMENT
                            </Typography>
                            <CustomButton borderRadius='10px' backgroundColor="var(--primary)" color="white" padding="10px" className="fullwidth" handleClick={() => setOpenDepositPopup(true)} >
                                FUND WALLET
                            </CustomButton>
                        </div>
                        <div className="content-box" style={{ backgroundColor: 'black' }}>
                            <SwapHoriz sx={{ width: 50, height: 50 }} />
                            <Typography variant="h6" sx={{ letterSpacing: '3px' }}>
                                WITHDRAW FUNDS
                            </Typography>
                            <CustomButton borderRadius='10px' backgroundColor="var(--primary)" color="white" padding="10px" className="fullwidth" handleClick={() => setOpenPopup(true)} >
                                WITHDRAW FUNDS
                            </CustomButton>
                        </div>
                    </Masonry>
                </div>}
                {openPopup &&
                    <div className="popup" style={{
                        background: 'black', color: 'white', position: 'fixed', top: '0',
                        left: 0, zIndex: 200, width: '100%', height: '100vh'
                    }}>
                        <div className="flex" style={{
                            position: 'fixed', top: 10,
                            right: 10, zIndex: 200, justifyContent: 'flex-end'
                        }}>
                            <IconButton onClick={() => setOpenPopup(false)}>
                                <Clear sx={{ width: 23, height: 23, color: 'white' }} className="hide-on-large-only" />
                                <Clear sx={{ width: 50, height: 50, color: 'white' }} className="hide-on-med-and-down" />
                            </IconButton>
                        </div>
                        <div className="mid margin-auto flex align-center" style={{ height: '100vh' }}>
                            <div style={{ width: '100%' }}>
                                <Spacebox padding="20px">
                                    <Typography variant="h3" className="bold">
                                        Enter amount below
                                    </Typography>
                                    <Spacebox padding="10px" />
                                    <input type="number" className="fullwidth" placeholder="Enter amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                                    <Spacebox padding="2px" />
                                    {service_fee && <small style={{ display: 'block', color: 'grey' }}>You will be charged a service fee of {service_fee * amount} usdt for this transaction</small>}
                                    <Spacebox padding="10px" />
                                    <CustomButton backgroundColor="var(--secondary)" color="white" padding="10px" className="fullwidth" borderRadius="10px" handleClick={() => withdraw()}>
                                        {loading ? 'Please wait..' : `WITHDRAW ${amount} USDT`}
                                    </CustomButton>
                                </Spacebox>
                            </div>
                        </div>
                    </div>}
                {openDepositPopup &&
                    <div className="popup" style={{
                        background: 'black', color: 'white', position: 'fixed', top: '0',
                        left: 0, zIndex: 200, width: '100%', height: '100vh'
                    }}>
                        <div className="flex" style={{
                            position: 'fixed', top: 10,
                            right: 10, zIndex: 200, justifyContent: 'flex-end'
                        }}>
                            <IconButton onClick={() => setOpenDepositPopup(false)} className="hide-on-med-and-down">
                                <Clear sx={{ width: 50, height: 50, color: 'white' }} />
                            </IconButton>
                            <IconButton onClick={() => setOpenDepositPopup(false)} className="hide-on-large-only">
                                <Clear sx={{ width: 23, height: 23, color: 'white' }} />
                            </IconButton>
                        </div>
                        <div className="mid margin-auto flex align-center" style={{ height: '100vh' }}>
                            <div style={{ width: '100vw' }}>
                                <Spacebox padding="20px">
                                    <Typography variant="h3" className="bold">
                                        Fund Wallet
                                    </Typography>
                                    <Spacebox padding="3px" />
                                    <Typography variant="body2">
                                        Please enter the desired funding amount for your wallet.
                                    </Typography>
                                    <Spacebox padding="10px" />
                                    <input type="number" placeholder="Enter amount" value={depositAmount} onChange={(e) => setDepositAmount(e.target.value)} />
                                    <Spacebox padding="2px" />
                                    {service_fee && <small style={{ display: 'block', color: 'grey' }}>You will be charged a service fee of {service_fee * depositAmount} usdt for this transaction</small>}
                                    <Spacebox padding="10px" />
                                    <div className="dark-theme border20 space-box-20">
                                        <Typography variant="h5" textAlign='center' className="bold">
                                            {service_fee && <span>Send {parseFloat(depositAmount) + (parseFloat(depositAmount) * parseFloat(service_fee))} to the address below</span>}
                                            {!service_fee && <span>Send {depositAmount} to the address below</span>}
                                        </Typography>
                                        <Spacebox padding="5px" />
                                        <div className="address-holder flex space-box space-between align-center" style={{ backgroundColor: 'black', borderRadius: '10px' }} >
                                            {service_fee && <span className="address_span">{wallets[1].address}</span>}
                                            {!service_fee && <span className="address_span">{t_address}</span>}
                                            <Tooltip title={copyText} placement="bottom">
                                                <IconButton onClick={copyAddress}>
                                                    {copyText === 'Copied' ? <CheckBoxOutlined sx={{ width: 20, height: 20, color: 'green' }} /> : <CopyAllOutlined sx={{ width: 20, height: 20, color: 'white' }} />}
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <Spacebox padding="10px" />
                                    <CustomButton backgroundColor="var(--secondary)" color="white" padding="10px" className="fullwidth" borderRadius="10px" handleClick={() => deposit()}>
                                        {loading ? 'Please wait...' :
                                            <>
                                                {service_fee && <span>I HAVE SENT {parseFloat(depositAmount) + (parseFloat(depositAmount) * parseFloat(service_fee))} USDT TO THE ABOVE ADDRESS</span>}
                                                {!service_fee && <span>I HAVE SENT {depositAmount} USDT TO THE ABOVE ADDRESS</span>}
                                            </>}
                                    </CustomButton>
                                </Spacebox>
                            </div>
                        </div>
                    </div>}
            </div>
        </OnAuth>
    );
}

export default Wallet;