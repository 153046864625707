import { Typography } from "@mui/material";
import { useEffect, useState } from "react";

const Footer = () => {
    const [year, setYear] = useState('2023');
    const d = new Date();

    useEffect(() => {
        setYear(d.getFullYear());
        // eslint-disable-next-line
    }, [])
    return (
        <div className="footer">
            <Typography variant="body2" align="center" color='gray'>Azixbot © {year}</Typography>
        </div>
    );
}
 
export default Footer;