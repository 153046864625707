import { useEffect, useState } from "react";
import Toast from "../components/Toast";
import OnAuth from "../components/onAuth";
import { Grid, LinearProgress, Typography } from "@mui/material";
import Spacebox from "../components/styles/Spacebox";
import supabase from "../config/supabaseClient";
import cookies from "../utilities/Cookies";
import { TrendingUp } from "@mui/icons-material";

const Analtics = () => {
    document.querySelector('title').innerHTML = 'Analytics | Azixbot';

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('msg');

    const [showCongratulations, setShowCongratulations] = useState(false);

    const [activeTransaction, setActiveTrans] = useState(null);
    const [activeOutlines, setActiveOutlines] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    let stringified_user = cookies.getCookies('user');
    const user = stringified_user ? JSON.parse(stringified_user) : '';


    useEffect(() => {
        const getActiveTransaction = async () => {
            const { data, error } = await supabase.from('transactions')
                .select()
                .eq('email', user.email)
                .eq('active', true)

            if (error) {
                setSeverity('error');
                setToastMsg('An error occured, Kindly refresh the page');
                setOpen(true);
                setIsLoading(false);
            }

            if (data) {
                if (data.length > 0) {
                    setActiveTrans(data[0]);
                    // get outlines
                    const { data: data2, error: error2 } = await supabase.from('outlines')
                        .select()
                        .eq('email', user.email)
                        .eq('transaction_id', data[0].id)

                    if (error2) {
                        setSeverity('error');
                        setToastMsg('An error occured, Kindly refresh the page');
                        setOpen(true);
                        setIsLoading(false);
                    }

                    if (data2) {
                        if (data2.length > 0) {
                            setActiveOutlines(data2);
                            setIsLoading(false);
                        } else {
                            setIsLoading(false);
                        }
                        //check when transaction ends
                        transactionEndTime(data[0]);
                    }

                } else {
                    setErrorMsg('You have no active transactions at this time');
                    setIsLoading(false)
                }
            }
        }

        getActiveTransaction()
        // eslint-disable-next-line
    }, [])

    const transactionEndTime = async (transaction) => {
        const d = new Date()
        const today_ = `${d.getDate()}-${getMonthInWord(d.getMonth())}-${d.getFullYear()}`;

        const endTime = transaction.end_time;

        if (today_ === endTime || isCurrentMonthGreater(today_, endTime)) {
            // get percentage
            const amount = (parseFloat(transaction.amount) * parseFloat(transaction.percentage)) + parseFloat(transaction.amount)
            // update user's balance and transaction completion
            const { data: data3, error: error3 } = await supabase.from('users')
                .update({ balance: amount })
                .eq('email', transaction.email)
                .select()

            if (error3) {
                setSeverity('error');
                setToastMsg('An error occured while funding your wallet.')
                setOpen(true);
                console.log(error3)
            }

            if (data3) {
                changeTransactionStatus(transaction)
                let user_ = {
                    ...user
                }
                user_.balance = amount;
                cookies.setCookies('user', JSON.stringify(user_), 0.5);
                setSeverity('success');
                setToastMsg('Congratulations, your account has been funded successfully');
                setOpen(true);
                setShowCongratulations(true);
                setTimeout(() => {
                    setShowCongratulations(false);
                }, 5000);
            }
        }
    }

    const isCurrentMonthGreater = (today, end_time) => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        const _currentMonth = today.split("-")[1];
        const end_time_month = end_time.split("-")[1];
        let curr_index, end_time_index;
        months.forEach((month, index) => {
            if (month === _currentMonth)
                curr_index = index
            
            if (month === end_time_month)
                end_time_index = index
        })
        if (curr_index > end_time_index) {
            return true
        }else if( curr_index === end_time_index) {
            return parseInt(today.split("-")[0]) > parseInt(end_time.split("-")[0]) ?  true : false
        }else {
            return false
        }
    }

    const changeTransactionStatus = async (transaction) => {
        const {data: data4, error: error4} = await supabase.from('transactions')
        .update({active: false, completed: true})
        .eq('id', transaction.id)
        .eq('email', transaction.email)
        .select()

        if(error4) {
            setSeverity('error');
            setToastMsg('An error occured while funding your wallet.')
            setOpen(true);
        }

        if(data4) {
            return
        }

    }

    const convertToDate = (timestamp) => {
        var date = new Date(timestamp);

        var day = date.getDate();
        var month = date.getMonth();
        var year = date.getFullYear();

        return `${day}  ${getMonthInWord(month)}  ${year}`
    }

    const getMonthInWord = (month) => {
        switch (month) {
            case 0:
                return 'Jan'
            case 1:
                return 'Feb'
            case 2:
                return 'Mar'
            case 3:
                return 'Apr'
            case 4:
                return 'May'
            case 5:
                return 'Jun'
            case 6:
                return 'Jul'
            case 7:
                return 'Aug'
            case 8:
                return 'Sep'
            case 9:
                return 'Oct'
            case 10:
                return 'Nov'
            case 11:
                return 'Dec'
            default:
                return 'NAM'
        }
    }

    const convertDecimalToPercentage = (decimal) => {
        return parseFloat(decimal) * 100
    }

    return (
        <OnAuth>
            <div className="analytics dark-theme border20 hide-scrollbar suitable-width" style={{ minHeight: '100%', maxHeight: '100%', overflowY: 'scroll', padding: '20px' }}>
                <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
                {isLoading && <LinearProgress />}
                {errorMsg && <>
                    <div>
                        <Typography>
                            You have no active transactions at this time
                        </Typography>
                    </div>
                </>}

                {activeTransaction &&
                    <div>
                        <Typography variant="h5" className="bold">
                            Active Transaction
                        </Typography>
                        <Spacebox padding="10px" />
                        <Grid container spacing={3}>
                            <Grid item xs={12} lg={6}>
                                <div className="content-box classyBG">
                                    <Spacebox padding="20px">
                                        <Typography variant="small" className="bold">
                                            Investment Amount
                                        </Typography>
                                        <Typography variant="h2">
                                            {activeTransaction.amount} <small style={{ fontSize: '15px' }}>USDT</small>
                                        </Typography>
                                        <Typography variant="small">
                                            amount invested on the {convertToDate(activeTransaction.timestamp)}
                                        </Typography>
                                    </Spacebox>
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <div className="content-box classyBG2">
                                    <Spacebox padding="20px">
                                        <Typography variant="small" className="bold">
                                            Return of Investment
                                        </Typography>
                                        <Typography variant="h2">
                                            {convertDecimalToPercentage(activeTransaction.percentage)}%
                                        </Typography>
                                        <Typography variant="small">
                                            Projected rate of returns based on weekly market trend
                                        </Typography>
                                    </Spacebox>
                                </div>
                            </Grid>
                        </Grid>
                        <Spacebox padding="20px" />
                        <Typography variant="h6" className="bold">
                            My Investment Trends
                        </Typography>
                        <div className="outlines_history hide-scrollbar" style={{ height: '250px', overflowY: 'scroll' }}>
                            <table>
                                <thead>
                                    <tr className="table-head">
                                        <td>Date</td>
                                        <td>Values</td>
                                        <td>Returns</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                {activeOutlines && <tbody>
                                    {activeOutlines.map((activeOutline, index) => (
                                        <tr key={index} className="data-row">
                                            <td>
                                                {activeOutline.date}
                                            </td>
                                            <td>
                                                {activeOutline.amount}usdt
                                            </td>
                                            <td>
                                                {convertDecimalToPercentage(activeOutline.percentage)}%
                                            </td>
                                            <td>
                                                <TrendingUp sx={{ width: 18, height: 18, color: 'var(--primary)' }} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>}
                            </table>
                        </div>
                    </div>}
                {showCongratulations && <div className="sprinkles">

                </div>}
            </div>
        </OnAuth>
    );
}

export default Analtics;