import { CandlestickChartOutlined, ChevronLeftOutlined, ChevronRightOutlined, HistoryOutlined, Logout, MessageOutlined, WalletOutlined } from "@mui/icons-material";
import { IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Spacebox from "./styles/Spacebox";
import cookies from "../utilities/Cookies";
import { useState } from "react";

const Sidebar = () => {

    const navigate = useNavigate();
    const location = useLocation()

    const [openSideBar, setOpenSideBar] = useState(false);

    const menuItems = [
        {
            name: 'Chat',
            icon: <MessageOutlined style={{color: 'white', fontSize: '23px'}}/>,
            path: '/chat'
        },
        // {
        //     name: 'Profile',
        //     icon: <Person2Outlined style={{color: 'white', fontSize: '23px'}}/>,
        //     path: '/profile'
        // },
        {
            name: 'Analytics',
            icon: <CandlestickChartOutlined style={{color: 'white', fontSize: '23px'}}/>,
            path: '/analytics'
        },
        {
            name: 'Wallet',
            icon: <WalletOutlined style={{color: 'white', fontSize: '23px'}}/>,
            path: '/wallet'
        },
        {
            name: 'Transactions',
            icon: <HistoryOutlined style={{color: 'white', fontSize: '23px'}}/>,
            path: '/transactions'
        }
    ];

    const logout = () => {
        cookies.deleteCookies('user');
        navigate('/');
    }


    const showSidebar = () => {
        setOpenSideBar(!openSideBar);
        document.querySelector('.sidebar').classList.toggle('show')
    }


    return (
        <div className="sidebar">
            <div className="opener hide-on-med-and-up">
                <IconButton onClick={showSidebar}>
                    { openSideBar ? <ChevronLeftOutlined style={{color: 'white', fontSize: '23px'}} /> :
                        <ChevronRightOutlined style={{color: 'white', fontSize: '23px'}}/>}
                </IconButton>
            </div>
            <Spacebox padding="10px" className="hide-on-large-only"/>
            <List>
                {menuItems.map((menuItem, index) => (
                    <ListItem key={index} sx={{borderLeft: location.pathname === menuItem.path ?  '5px solid var(--primary)' : '5px solid transparent'}}>
                        <ListItemButton onClick={() => navigate(`${menuItem.path}`)}>
                            <ListItemIcon>
                                {menuItem.icon}
                            </ListItemIcon>
                            <ListItemText  primary={<span style={{fontSize: '20px'}}>{menuItem.name}</span>} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Spacebox padding='50px' />
            <List>
                <ListItem>
                    <ListItemButton onClick={logout}>
                        <ListItemIcon>
                            <Logout style={{color: 'white', fontSize: '23px'}}/>
                        </ListItemIcon>
                        <ListItemText primary={<span style={{fontSize: '20px'}}>Logout</span>}/>
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    );
}

export default Sidebar;