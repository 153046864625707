import { IconButton, LinearProgress, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from "@mui/material";
import Toast from "../components/Toast";
import OnAuth from "../components/onAuth";
import { useState } from "react";
import cookies from "../utilities/Cookies";
import string from "../utilities/Strings";
import Spacebox from "../components/styles/Spacebox";
import CustomGrid from "../components/styles/Grid";
import { CheckBox, CheckBoxOutlined, Clear, CopyAllOutlined } from "@mui/icons-material";
import CustomButton from "../components/styles/Custombutton";
import useFetch from "../hooks/useFetch";

const Paymentplan = () => {
    document.querySelector('title').innerHTML = 'Payment Plan | Azixbot';

    const { data: service_fee } = useFetch(process.env.REACT_APP_SERVER_URL + 'gas-fee');
    
    const wallets = [
        {
            wallet: 'Coinbase',
            address: '0x0b5a8d07f63830DB55c0Ca8e5b0323f03aF39EE1'
        },
        {
            wallet: 'Binance',
            address: '0x0b5a8d07f63830DB55c0Ca8e5b0323f03aF39EE1'
        },
        {
            wallet: 'Trustwallet',
            address: '0x1379084643317860c2285dffaf68d3d4384fe0ac'
        }
    ];

    const t_address = '0x947Ce3c42434C3461ed42923BB28071B7B948eDF';
    const [copyText, setCopyText] = useState('Copy to clipboard');

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('msg');

    const [chosenPlan, setChosenPlan] = useState(null)
    const [amount, setAmount] = useState(null);


    const [isMonthly, setIsMonthly] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [openDepositPopup, setOpenDepositPopup] = useState(false);


    let stringify_user = cookies.getCookies('user')
    const user = stringify_user ? JSON.parse(stringify_user) : null;


    const price_plans = [
        {
            name: 'Free Plan',
            monthly: 'Free',
            yearly: 'Free',
            list: ['Limited access to Basic Trading Strategies and Indicators', 'Restricted number of trades ( maximum number of 3 trades )', 'Only 5% - 10% weekly self bot executed trade profits.', 'Basic customer support ']
        },
        {
            name: 'Basic Plan',
            monthly: service_fee ? Math.ceil((service_fee * 99) + 99) : 99,
            yearly: service_fee ? Math.ceil((service_fee * 1000) + 1000) : 1000,
            list: ['Limited access to Basic Trading Strategies and Indicators', 'Restricted number of trades per month ( maximum number of 10 trades )', 'Only 5% - 10% weekly self bot executed trade profits.', 'Basic customer support ', '50% maximum roi monthly']
        },
        {
            name: 'Standard Plan',
            monthly: service_fee ? Math.ceil((service_fee * 499) + 499) : 499,
            yearly: service_fee ? Math.ceil((service_fee * 5000) + 5000) : 5000,
            list: ['Access to a wider range of trading strategy with more accurate trade.', 'Ai algorithm strategy recommendation increased number of trades per month.', 'Customization option for trading parameters more access to increased accurate precision.', 'Access to 72 hours accurate market signal and trades.', 'Make over 10% - 15% profit on 72 hours trades on different pairs.', 'Very low risk trade and high risk management bot incorporation.', 'Accumulated bot trading profit of 120% monthly.'],
        },
        {
            name: 'Premium Plan',
            monthly: service_fee ? Math.ceil((service_fee * 999) + 999) : 999,
            yearly: service_fee ? Math.ceil((service_fee * 7399) + 7399) : 7399,
            list: ['Access to advanced and exclusive trading strategies and indicators.', 'AI Algorithm strategy recommendation.', 'Higher number of trades execution per month.', 'Exclusive Day trading bot execution of over 10% daily profit.', 'AI generated signal of daily market data on your preferred trading pairs.', 'Over 10 active pair trade execution daily.', 'accumulated Bot profit of over 300% profit monthly', 'Connect your wallet and exchange account for automated trading and funding']
        },
    ];


    const selectedPlan = (name, monthly, yearly) => {
        setChosenPlan(name);
        isMonthly ? setAmount(monthly) : setAmount(yearly)
        setOpenDepositPopup(true);
    }

    const copyAddress = () => {
        if (service_fee) {
            window.navigator.clipboard.writeText(wallets[1].address)
            setCopyText('Copied')
        } else {
            window.navigator.clipboard.writeText(t_address)
            setCopyText('Copied')
        }
    }


    const confirmDeposit = () => {
        setIsLoading(true);

        fetch(process.env.REACT_APP_SERVER_URL + 'payment_plan_deposit', {
            mode: 'cors',
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Accept': 'application/json', 'Origin': 'http://localhost:3000'},
            body: JSON.stringify({user: user, amount: amount, plan: chosenPlan, monthly: isMonthly})
        }).then(res => res.json())
        .then(data => {
            if(data.success) {
                setToastMsg('Your request is pending');
                setSeverity('success');
                setOpen(true)
                setIsLoading(false);
                setOpenDepositPopup(false);
            }else {
                setToastMsg(data.msg);
                setSeverity('error');
                setOpen(true)
                setIsLoading(false);
                console.log(data.error)
            }
        }).catch(err => {
            setToastMsg('An error ocuured');
            setSeverity('error');
            setOpen(true)
            setIsLoading(false);
            console.log(err)
        })
    }

    return (
        <OnAuth>
            {user && <div className="payment_plan dark-theme border20 hide-scrollbar suitable-width" style={{ minHeight: '100%', maxHeight: '100%', overflowY: 'scroll', padding: '20px' }}>
                <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
                <Typography variant="h5">
                    You are currently<br /> on the <span className="bold">{string.capitalise(user.type)} plan.</span>
                </Typography>
                <Spacebox padding='10px' />
                <div className="flex justify-center fullwidth">
                    <div className={`selectorHolder ${isMonthly ? '' : 'annually'} flex justify-center border20 align-center`} style={{ width: 'fit-content', backgroundColor: 'black', padding: '5px' }}>
                        <Spacebox padding='10px 30px' className="border20 pointer hoverUp">
                            <div onClick={() => setIsMonthly(true)}>
                                Monthly
                            </div>
                        </Spacebox>
                        <Spacebox padding='10px' />
                        <Spacebox padding='10px 30px' className="border20 pointer hoverUp">
                            <div onClick={() => setIsMonthly(false)}>
                                Annually
                            </div>
                        </Spacebox>
                    </div>
                </div>
                <Spacebox padding='10px' />
                <CustomGrid grid={2} gap="20px" >
                    {price_plans.map((plan, index) => (
                        <div className="price-plan border20" style={{ backgroundColor: '#ffffff1a' }} key={index}>
                            <Spacebox padding="20px">
                                <div className="flex space-between align-center">
                                    <div>
                                        <Typography variant="h6" className="bold">
                                            {plan.name}
                                        </Typography>
                                        <span>
                                            Plan
                                        </span>
                                    </div>
                                    <div>
                                        <Typography variant="body1">
                                            {isMonthly ? "$" + (plan.monthly).toLocaleString() : "$" + (plan.yearly).toLocaleString()}
                                        </Typography>
                                        <span>
                                            {isMonthly ? "Monthly" : "Annually"}
                                        </span>
                                    </div>
                                </div>
                                <Spacebox padding='20px' />
                                <p>
                                    <small>Plan Includes</small>
                                </p>
                                <List>
                                    {plan.list.map((item, index) => (
                                        <ListItem key={index}>
                                            <ListItemIcon>
                                                <CheckBox sx={{ width: 20, height: 20, color: 'var(--primary)' }} />
                                            </ListItemIcon>
                                            <ListItemText primary={item} primaryTypographyProps={{ fontSize: 15 }} />
                                        </ListItem>
                                    ))}
                                </List>
                                <Spacebox padding='20px' />
                                {plan.name !== 'Free Plan' && <CustomButton borderRadius='20px' backgroundColor='var(--primary)' color='white' padding='20px' className="fullwidth" handleClick={() => selectedPlan(plan.name, plan.monthly, plan.yearly)}>
                                    Migrate To {plan.name} Plan
                                </CustomButton>}
                            </Spacebox>
                        </div>
                    ))}
                </CustomGrid>
                {openDepositPopup &&
                    <div className="popup" style={{
                        background: 'black', color: 'white', position: 'fixed', top: '0',
                        left: 0, zIndex: 200, width: '100%', height: '100vh'
                    }}>
                        <div className="flex" style={{
                            position: 'fixed', top: 10,
                            right: 10, zIndex: 200, justifyContent: 'flex-end'
                        }}>
                            <IconButton onClick={() => setOpenDepositPopup(false)} className="hide-on-med-and-down">
                                <Clear sx={{ width: 50, height: 50, color: 'white' }} />
                            </IconButton>
                            <IconButton onClick={() => setOpenDepositPopup(false)} className="hide-on-large-only">
                                <Clear sx={{ width: 23, height: 23, color: 'white' }} />
                            </IconButton>
                        </div>
                        <div className="mid margin-auto flex align-center" style={{ height: '100vh' }}>
                            <div style={{ width: '100vw' }}>
                                <Spacebox padding="20px">
                                    <Typography variant="h5" className="bold">
                                        {chosenPlan}
                                    </Typography>
                                    <Spacebox padding="10px" />
                                    <div className="dark-theme border20 space-box-20">
                                        <Typography variant="h5" textAlign='center' className="bold">
                                            <span>Send {(amount).toLocaleString()} to the address below</span>
                                        </Typography>
                                        <Spacebox padding="5px" />
                                        <div className="address-holder flex space-box space-between align-center" style={{ backgroundColor: 'black', borderRadius: '10px' }} >
                                            {service_fee && <span className="address_span">{wallets[1].address}</span>}
                                            {!service_fee && <span className="address_span">{t_address}</span>}
                                            <Tooltip title={copyText} placement="bottom">
                                                <IconButton onClick={copyAddress}>
                                                    {copyText === 'Copied' ? <CheckBoxOutlined sx={{ width: 20, height: 20, color: 'green' }} /> : <CopyAllOutlined sx={{ width: 20, height: 20, color: 'white' }} />}
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <small>The above USDT address is on Ethereum(ERC20) network</small>
                                    </div>
                                    <Spacebox padding='10px'>
                                        {isLoading && <LinearProgress />}
                                    </Spacebox>
                                    <CustomButton
                                        backgroundColor="var(--secondary"
                                        borderRadius="10px"
                                        className="fullwidth"
                                        padding="10px"
                                        color="white"
                                        handleClick={() => confirmDeposit()}
                                    >
                                        I have sent {amount} to the above address
                                    </CustomButton>
                                </Spacebox>
                            </div>
                        </div>
                    </div>}
            </div>}
        </OnAuth>
    );
}

export default Paymentplan;