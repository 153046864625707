import { Avatar, Typography } from "@mui/material";
import Spacebox from "./styles/Spacebox";
import { grey } from "@mui/material/colors";

const Testimonies = ({index}) => {

    const testimonies = [
        {
            testimony: 'Honestly Azixbot has helped me make some serious money',
            image: '/assets/will.png',
            author: '@William_'
        },
        {
            testimony: 'Coming across Azixbot was the best thing yet',
            image: '/assets/dd.jpg',
            author: '@Maryy09_'
        },
        {
            testimony: 'Finally, An AI that actually makes me money',
            image: '/assets/bridget.jpg',
            author: '@0xBridget'
        },
    ]

    return (
        <div className="testimony-div">
            <div className="testimonial">
                <Typography variant="h5">
                    {testimonies[index].testimony}
                </Typography>
                <Spacebox padding="10px" />
                <div className="flex align-center">
                    <Avatar alt="azixbot" src={testimonies[index].image} />
                    <Spacebox padding="5px" />
                    <span style={{ color: grey[600] }}>{testimonies[index].author}</span>
                </div>
            </div>
        </div>
    );
}

export default Testimonies;