import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Bridge from './auth/Bridge';
import Login from './auth/Login';
import Signup from './auth/Signup';
import Wallet from './pages/Wallet';
import Transactions from './pages/Transactions';
import Analtics from './pages/Analytics';
import Approve from './pages/admin/Approve';
import Paymentplan from './pages/Plan';
import ChangePlan from './pages/admin/PlanChange';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#3ECF8E',
      },
      secondary: {
        main: '#C13071',
      },
    },
    typography: {
      fontFamily: "Source Sans Pro",
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightBold: 700,
      fontSize: 20
    }
  })
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route exact path="/" element={<Bridge />} />
          <Route exact path="/chat" element={<Home />} />
          <Route exact path="/wallet" element={<Wallet />} />
          <Route exact path="/transactions" element={<Transactions />} />
          <Route exact path="/analytics" element={<Analtics />} />
          <Route exact path="/payment-plan" element={<Paymentplan />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/admin/secure/approve" element={<Approve />} />
          <Route exact path="/admin/secure/plan-change" element={<ChangePlan />} />
          {/* TODO create not found page */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
