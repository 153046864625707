import { Grid, LinearProgress, Typography } from "@mui/material";
import OnAuth from "../components/onAuth";
import Spacebox from "../components/styles/Spacebox";
import { useEffect, useState } from "react";
import supabase from "../config/supabaseClient";
import Toast from "../components/Toast";
import { Autorenew, Cancel, CheckOutlined, Search } from "@mui/icons-material";
import cookies from "../utilities/Cookies";

const Transactions = () => {
    document.querySelector('title').innerHTML = 'Transactions | Azixbot';

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('msg');

    const [isLoading, setIsLoading] = useState(false);

    let stringified_user = cookies.getCookies('user');
    const user = stringified_user ? JSON.parse(stringified_user) : '';


    const [type, setType] = useState('pending');

    const [transactions, setTransactions] = useState(null);

    const [search, setSearch] = useState('');


    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const { data, error } = await supabase.from('transactions')
                .select()
                .eq('email', user.email)
                .eq('active', false)
                .order('timestamp', { ascending: false })

            if (error) {
                setSeverity('error');
                setToastMsg('an error occured');
                setOpen(true);
                setIsLoading(false);
            }

            if (data) {
                const filtered_data = data.filter(dt => dt.status === type);
                setTransactions(filtered_data);
                setIsLoading(false);
            }
        }

        getData();

        // eslint-disable-next-line
    }, [type])

    const convertToDate = (timestamp) => {
        var date = new Date(timestamp);

        var day = date.getDate();
        var month = date.getMonth();
        var year = date.getFullYear();

        return `${day}  ${getMonthInWord(month)}  ${year}`
    }

    const getMonthInWord = (month) => {
        switch (month) {
            case 0:
                return 'Jan'
            case 1:
                return 'Feb'
            case 2:
                return 'Mar'
            case 3:
                return 'Apr'
            case 4:
                return 'May'
            case 5:
                return 'Jun'
            case 6:
                return 'Jul'
            case 7:
                return 'Aug'
            case 8:
                return 'Sep'
            case 9:
                return 'Oct'
            case 10:
                return 'Nov'
            case 11:
                return 'Dec'
            default:
                return 'NAM'
        }
    }

    const styleStatus = (status) => {

        const status_options = {
            'pending': {
                name: <small style={{ color: '#24f4ff' }}>Pending</small>,
                icon: <Autorenew sx={{ color: '#24f4ff', width: 13, height: 13 }} />,
                color: '#24f4ff2a'
            },
            'successful': {
                name: <small style={{ color: '#3ECF8E' }}>Successful</small>,
                icon: <CheckOutlined sx={{ color: '#3ECF8E', width: 13, height: 13 }} />,
                color: '#3ECF8E2a'
            },
            'failed': {
                name: <small style={{ color: '#ff0000' }}>Failed</small>,
                icon: <Cancel sx={{ color: '#ff0000', width: 13, height: 13 }} />,
                color: '#ff00002a'
            }
        }

        return (
            <div className="flex align-center" style={{ backgroundColor: status_options[status].color, borderRadius: '100px', padding: '5px 10px', width: 'fit-content', margin: 'auto' }}>
                {status_options[status].icon}
                <Spacebox padding='5px' />
                {status_options[status].name}
                <Spacebox padding='5px' />
            </div>
        )
    }

    const getTransactionWithID = async () => {
        setIsLoading(true);
        const { data, error } = await supabase.from('transactions')
            .select()
            .eq('email', user.email)
            .eq('active', false)
            .eq('id', search)
            .order('timestamp', { ascending: false })

        if (error) {
            setSeverity('error');
            setToastMsg('an error occured');
            setOpen(true);
            setIsLoading(false);
        }

        if (data) {
            setTransactions(data);
            setIsLoading(false);
        }
    }

    return (
        <OnAuth>
            <div className="transactions dark-theme border20 hide-scrollbar suitable-width" style={{ minHeight: '100%', maxHeight: '100%', overflowY: 'scroll', padding: '20px' }}>
                <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
                <Typography variant="body2" className="bold">
                    Transactions
                </Typography>
                <Spacebox padding="2px" />
                <span style={{ color: '#ababab' }}>
                    A list of all {type} transactions
                </span>
                <Spacebox padding="10px" />
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={8}>
                        <div className="flex align-center" style={{ border: '1px solid rgba(255, 255, 255, 0.2)', borderRadius: '10px', padding: '5px 10px' }}>
                            <Search sx={{ color: 'white', width: 20, height: 20 }} />
                            <Spacebox padding="5px" />
                            <input
                                type="text"
                                id="search_input"
                                placeholder="Search by ID"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyDown={(e) => e.code === 'Enter' ? getTransactionWithID(e.target.value) : ''}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6} lg={4}>
                        <select
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                        >
                            <option value="pending">Pending</option>
                            <option value="successful">Successful</option>
                            <option value="failed">Failed</option>
                        </select>
                    </Grid>
                </Grid>
                <Spacebox padding='20px'>
                    {isLoading && <LinearProgress />}
                </Spacebox>
                <div className="table-wrap hide-scrollbar">
                    <table>
                        <thead>
                            <tr className="table-head">
                                <td>ID</td>
                                <td>Date</td>
                                <td>Type</td>
                                <td>Amount</td>
                                <td>Status</td>
                            </tr>
                        </thead>
                        {transactions &&
                            <tbody className="hide-scrollbar" style={{ height: '100%', overflowY: 'hidden' }}>
                                {transactions.length > 0 ?
                                    <>
                                        {transactions.map((transaction, index) => (
                                            <tr key={index} className="data-row">
                                                <td>{transaction.id}</td>
                                                <td>{convertToDate(transaction.timestamp)}</td>
                                                <td>{transaction.deposit ? 'Deposit' : 'Withdraw'}</td>
                                                <td>{transaction.amount}</td>
                                                <td>{styleStatus(transaction.status)}</td>
                                            </tr>
                                        ))}
                                    </>
                                    :
                                    <tr>
                                        <td colSpan={5} style={{ textAlign: 'center' }}>You have no transactions at this time</td>
                                    </tr>}
                            </tbody>
                        }
                    </table>
                </div>
            </div>
        </OnAuth>
    );
}

// transaction structure
// ID - Date - Type - Amount - Status

export default Transactions;