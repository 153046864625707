import { Avatar, IconButton, LinearProgress, Tooltip, Typography } from "@mui/material";
import Spacebox from "./styles/Spacebox";
import { Launch, Send } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import Toast from "./Toast";
import supabase from "../config/supabaseClient";
import cookies from "../utilities/Cookies";
import { grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";

const Messages = () => {
    const [prompt, setPrompt] = useState('');
    const [loading, setLoading] = useState(false);

    const messageRef = useRef();

    const [messageListener, setMessageListener] = useState(0);
    const [messages, setMessages] = useState(null);
    const [noMessages, setNoMessages] = useState(true);

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('msg');

    const navigate = useNavigate();

    let stringified_user = cookies.getCookies('user');
    const user = stringified_user ? JSON.parse(stringified_user) : '';

    const sendMessage = () => {
        setLoading(true);
        if (prompt === '') {
            setSeverity('error');
            setToastMsg('Promt is empty');
            setOpen(true);
            setLoading(false);
            return
        }

        fetch(process.env.REACT_APP_SERVER_URL + 'ask', {
            method: 'POST',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Origin': 'http://localhost:3000' },
            body: JSON.stringify({ question: prompt })
        }).then(res => res.json())
            .then(async (response) => {
                if (response.success) {
                    const { data, error } = await supabase.from(`messages`)
                        .insert([{
                            id: Date.now(),
                            timestamp: Date.now(),
                            message: prompt,
                            email: user.email,
                            user: true,
                            action: false
                        }]).select()

                    if (data) {
                        // add response
                        const { data: res_data, error: res_error } = await supabase.from(`messages`)
                            .insert([{
                                id: Date.now(),
                                timestamp: Date.now(),
                                message: response.answer,
                                email: user.email,
                                user: false,
                                action: response.showAction
                            }]).select()

                        if (res_data) {
                            setPrompt('');
                            setLoading(false);
                            // trigger messageListener to show messages
                            setMessageListener(Date.now());
                        } else if (res_error) {
                            setSeverity('error');
                            setToastMsg('An error occured');
                            setOpen(true);
                            setLoading(false);
                            console.log('Res-Error', res_error);
                        }
                    } else if (error) {
                        setSeverity('error');
                        setToastMsg('An error occured');
                        setOpen(true);
                        setLoading(false);
                        console.log(error);
                    }

                } else {
                    setSeverity('error');
                    setToastMsg(response.msg);
                    setOpen(true);
                    console.log(response.error);
                    setLoading(false);
                }
            }).catch(err => {
                setSeverity('error');
                setToastMsg('An error occured');
                setOpen(true);
                setLoading(false);
                console.log(err);
            })
    }

    //scroll to bottom of messages
    if (messages) messageRef.current.scroll({ top: messageRef.current.scrollHeight + 1000, behaviour: 'smooth' })

    useEffect(() => {
        const getMessages = async () => {
            const { data, error } = await supabase.from(`messages`)
                .select()
                .eq('email', user.email)
                .order('timestamp', { ascending: true })

            if (error) {
                // no messages

            }

            if (data) {
                if (data.length > 0) {
                    setMessages(data);
                    setNoMessages(false);
                }
            }
        }

        getMessages();
        setTimeout(() => {
            messageRef.current.scroll({ top: messageRef.current.scrollHeight + 1000, behaviour: 'smooth' })
        }, 700);
        // eslint-disable-next-line
    }, [messageListener])


    const formatMessage = (msg) => {
        if (!msg) {
            msg = 'I apologise, I couldn\'t process that question properly\n\nKindly rephrase the question and ask again.'
        }
        return msg.replaceAll('\n', '<br>')
    }

    return (
        <div className="messages-component" style={{ height: 'calc(100%)' }}>
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <div className="show-messages-container hide-scrollbar" style={{ width: '100%', minHeight: '85%', maxHeight: '85%', overflowY: 'scroll', }} ref={messageRef}>
                {noMessages && <>
                    <div className="border20 space-box-20" style={{ background: '#0000008a' }}>
                        <Typography variant='body2' color={grey[300]}>
                            ✨ I'm Azixbot, your AI bot designed to analyze market trends, historical data, and various indicators to provide insightful recommendations for potential cryptocurrency investments
                        </Typography>
                    </div>
                </>}
                {messages && <div className="messages">
                    {messages.map((msg, index) => (
                        <div className="message-holder flex space-between" key={index} style={{ borderLeft: msg.user ? '3px solid transparent' : '3px solid var(--primary)', marginBottom: 10, backgroundColor: msg.user ? 'transparent' : '#0000008a' }}>
                            <div className="flex space-box">
                                {msg.user ? <Avatar sx={{ backgroundColor: 'var(--secondary)', width: 30, height: 30 }}><span style={{ fontSize: 15 }}>{(user.fullname)[0]}</span></Avatar> : ''}
                                <Spacebox padding="10px" />
                                <div className="message" style={{ lineHeight: '1.5em' }} dangerouslySetInnerHTML={{ __html: formatMessage(msg.message) }}></div>
                            </div>
                            {msg.action && <div className="action flex align-center justify-center pointer" style={{ backgroundColor: '#ffffff5a' }} onClick={() => navigate('/wallet')}>
                                <Tooltip title="Invest" placement="right">
                                    <IconButton>
                                        <Launch sx={{ width: 20, height: 20, opacity: 0.6, color: 'white' }} />
                                    </IconButton>
                                </Tooltip>
                            </div>}
                        </div>
                    ))}
                </div>}
            </div>
            <div style={{ minHeight: '3%' }}>
                {loading && <LinearProgress />}
            </div>
            <div className="message-bar flex align-center hide-scrollbar" style={{ width: '100%', minHeight: '12%', maxHeight: '12%', overflowY: 'scroll' }}>
                <div className="send-message flex fullwidth">
                    <input type="text" style={{ flexGrow: '1' }}
                        className="send_msg"
                        placeholder="Enter a prompt here"
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        onKeyDown={(e) => e.code === 'Enter' ? sendMessage() : ''}
                    />
                    <Spacebox padding='5px' className="hide-on-med-and-down" />
                    <Spacebox padding='2px' className="hide-on-large-only" />
                    <IconButton onClick={() => sendMessage()} className="">
                        <Send sx={{ color: 'var(--primary)', opacity: prompt.length > 1 ? '1' : 0.2, width: 23, height: 23 }} />
                    </IconButton>
                </div>
            </div>
        </div>
    );
}

export default Messages;