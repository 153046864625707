import { useState } from "react";
import Header from "../../components/Header";
import Toast from "../../components/Toast";
import Spacebox from "../../components/styles/Spacebox";
import { Container, IconButton, LinearProgress, Typography } from "@mui/material";
import CustomButton from "../../components/styles/Custombutton";
import supabase from "../../config/supabaseClient";
import { CheckOutlined } from "@mui/icons-material";

const ChangePlan = () => {

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('msg');

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('')
    const [users, setUsers] = useState(null);
    const [plan, setPlan] = useState('free');


    const getUser = async () => {
        setIsLoading(true);
        const { data, error } = await supabase.from('users')
            .select()
            .eq('email', email)

        if (error) {
            setToastMsg('An error occured');
            setSeverity('error')
            setOpen(true)
            setIsLoading(false)
            return
        }

        if (data) {
            if (data.length > 0) {
                setUsers(data);
                setPlan(data[0].type)
                setIsLoading(false)
            } else {
                setToastMsg('No user with that email found');
                setSeverity('error')
                setOpen(true)
                setIsLoading(false)
            }
        }
    }


    const updatePlan = async () => {
        setIsLoading(true)
        const {data, error} = await supabase.from('users')
        .update({'type': plan})
        .eq('email', email)
        .select()

        if(error) {
            setToastMsg('An error occured');
            setSeverity('error')
            setOpen(true)
            setIsLoading(false)
            return
        }

        if(data) {
            setToastMsg('User Details updated successfully');
            setSeverity('success')
            setOpen(true)
            setIsLoading(false)
        }
    }

    return (
        <div>
            <Header />
            <Spacebox padding="20px" />
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <Container className="border20 space-box-20 dark-theme">
                <Typography variant="h3" className="bold">
                    Approve Plan Change
                </Typography>
                <Typography variant="body2">
                    Enter user email
                </Typography>
                <Spacebox padding="10px" />
                <div className="mid margin-auto">
                    <input placeholder="User Email Address..." value={email} onChange={(e) => setEmail(e.target.value)} />
                    <Spacebox padding="5px" />
                    <CustomButton className="fullwidth" backgroundColor="var(--primary)" color="white" padding="10px" borderRadius="10px" handleClick={() => getUser()}>
                        Get User
                    </CustomButton>
                    <Spacebox padding='10px' />
                    {isLoading && <LinearProgress />}
                </div>
                {users && <table>
                    <thead>
                        <tr className="table-head">
                            <td>Firstname</td>
                            <td>Lastname</td>
                            <td>Email</td>
                            <td>Balance</td>
                            <td>Active Plan</td>
                            <td>Save Changes</td>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user, index) => (
                            <tr key={index} className="data-row">
                                <td>{(user.fullname).split(' ')[0]}</td>
                                <td>{(user.fullname).split(' ')[1]}</td>
                                <td>{user.email}</td>
                                <td>{user.balance}</td>
                                <td>
                                    <select value={plan} onChange={(e) => setPlan(e.target.value)}>
                                        <option value="free">Free</option>
                                        <option value="basic">Basic</option>
                                        <option value="standard">Standard</option>
                                        <option value="premium">Premium</option>
                                    </select>
                                </td>
                                <td>
                                    <IconButton onClick={() => updatePlan()}>
                                        <CheckOutlined sx={{ color: '#3ECF8E', width: 18, height: 18 }} />
                                    </IconButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>}
            </Container>
        </div>
    );
}

export default ChangePlan;