import { Container, Grid, Typography } from "@mui/material";
import Spacebox from "../components/styles/Spacebox";
import Header from "../components/Header";
import CustomButton from "../components/styles/Custombutton";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import { grey } from "@mui/material/colors";
import { useEffect } from "react";
import cookies from "../utilities/Cookies";


const Bridge = () => {

    const navigate = useNavigate();

    const login = () => {
        navigate('/login');
    }
    
    const signUp = () => {
        navigate('/signup');
    }


    useEffect(() => {
        if(cookies.getCookies('user')) {
            navigate('/chat');
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className="bridge-page">
            <Header />
            <Spacebox padding="20px"/>
            <Container>
                <div className="dark-theme" style={{borderRadius: '20px'}}>
                    <Spacebox padding="20px">
                        <Typography variant="h3" className="line-height-5 bold" color={grey[400]}>
                            <span style={{color: 'white'}}>Azixbot</span> is here to help you navigate the world of cryptocurrency investments with confidence.
                        </Typography>
                        <Spacebox padding='15px' />
                        <Typography>
                            Meet Azixbot: your AI bot is designed to analyze market trends, historical data, and various indicators to provide insightful recommendations for potential cryptocurrency investments.
                        </Typography>
                        <Spacebox padding='5px' />
                        <Typography>
                            While it's important to note that investing in cryptocurrencies carries inherent risks and our bot guarantees returns, and is very effective in assisting you in making well-informed decisions and excellent trading record.
                        </Typography>
                        <Spacebox padding='30px' />
                        <Grid container gap='20px'>
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                <CustomButton backgroundColor='var(--primary)' padding='15px 10px' color='#fff' handleClick={login} borderRadius='10px' className='fullwidth hoverup'>
                                    Login
                                </CustomButton>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                <CustomButton backgroundColor='var(--secondary)' padding='15px 10px' color='#fff' handleClick={signUp} borderRadius='10px' className='fullwidth hoverup'>
                                    Sign Up
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </Spacebox>
                </div>
            </Container>
            <Spacebox padding='20px' />
            <Footer />
        </div>
    );
}

export default Bridge;