import { Container, IconButton, LinearProgress, Typography } from "@mui/material";
import Header from "../../components/Header";
import Spacebox from "../../components/styles/Spacebox";
import CustomButton from "../../components/styles/Custombutton";
import { useState } from "react";
import supabase from "../../config/supabaseClient";
import { Autorenew, Cancel, CheckOutlined } from "@mui/icons-material";
import '../../index.css';
import Toast from "../../components/Toast";

const Approve = () => {

    const percentage = [
        {
            percentage: 0.05,
            values: [0.002, 0.002,0.012,0.018, 0.011, 0.001, 0.004]
        },
        {
            percentage: 0.07,
            values: [ 0.002, 0.004, 0.017, 0.019, 0.001, 0.002, 0.025]
        },
        {
            percentage: 0.06,
            values: [ 0.002, 0.001, 0.015, 0.019, 0.001, 0.002, 0.020]
        },
    ]

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('msg');

    const [transactionID, setTransactionId] = useState('');
    const [transaction, setTransaction] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    const getTransaction = async () => {
        if (transactionID !== '') {
            setIsLoading(true);
            const { data, error } = await supabase.from('transactions')
                .select()
                .eq('id', transactionID)
                .single()

            if (error) {
                alert('It seems '+ transactionID + " does not exist");
                console.log(error)
                setIsLoading(false);
            }

            if (data) {
                console.log(data);
                setTransaction(data);
                setIsLoading(false);
            }
        }
    }

    const convertToDate = (timestamp) => {
        var date = new Date(timestamp);

        var day = date.getDate();
        var month = date.getMonth();
        var year = date.getFullYear();

        return `${day}  ${getMonthInWord(month)}  ${year}`
    }

    const getMonthInWord = (month) => {
        switch (month) {
            case 0:
                return 'Jan'
            case 1:
                return 'Feb'
            case 2:
                return 'Mar'
            case 3:
                return 'Apr'
            case 4:
                return 'May'
            case 5:
                return 'Jun'
            case 6:
                return 'Jul'
            case 7:
                return 'Aug'
            case 8:
                return 'Sep'
            case 9:
                return 'Oct'
            case 10:
                return 'Nov'
            case 11:
                return 'Dec'
            default:
                return 'NAM'
        }
    }

    const styleStatus = (status) => {

        const status_options = {
            'pending': {
                name: <small style={{ color: '#24f4ff' }}>Pending</small>,
                icon: <Autorenew sx={{ color: '#24f4ff', width: 13, height: 13 }} />,
                color: '#24f4ff2a'
            },
            'successful': {
                name: <small style={{ color: '#3ECF8E' }}>Successful</small>,
                icon: <CheckOutlined sx={{ color: '#3ECF8E', width: 13, height: 13 }} />,
                color: '#3ECF8E2a'
            },
            'failed': {
                name: <small style={{ color: '#ff0000' }}>Failed</small>,
                icon: <Cancel sx={{ color: '#ff0000', width: 13, height: 13 }} />,
                color: '#ff00002a'
            }
        }

        return (
            <div className="flex align-center" style={{ backgroundColor: status_options[status].color, borderRadius: '100px', padding: '5px 10px', width: 'fit-content', margin: 'auto' }}>
                {status_options[status].icon}
                <Spacebox padding='5px' />
                {status_options[status].name}
                <Spacebox padding='5px' />
            </div>
        )
    }

    const changeStatus = async (status) => {
        setIsLoading(true);
        const timestamp = Date.now();
        const { data, error } = await supabase.from('transactions')
            .update({ status, timestamp })
            .eq('id', transactionID)
            .select()

        if (error) {
            alert('an error occured from the database');
            console.log(error)
            setIsLoading(false);
        }

        if (data) {
            if (status === 'successful') {
                // 1. set transaction active to true
                // 2. Generate one week interval for the transaction timestamp
                // 3. set the end time date to db which should be gotten from the 7th day in step 2
                // get a random number ranging from 0 to the length of percentage array and save that index to the percentage property on the db
                const active = true;
                // get seven days from now
                    let i = 0;
                    let rand =  Math.floor(Math.random() * ((percentage.length - 1) - 0 + 1)) + 0
                    let percent_ = percentage[rand] //gets a random percentage value from the percentage array
                    let daysOutline = [];
                    while (i < 7) {
                        let date = new Date(Date.now() + (i + 1) * 24 * 60 * 60 * 1000)

                        daysOutline.push({
                            day: `${date.getDate()}-${getMonthInWord(date.getMonth())}-${date.getFullYear()}`,
                            percentage: percent_.values[i]
                        })
                        i++
                    }

                    // save erry thing to db
                    const end_time = daysOutline[6].day;
                    //eslint-disable-next-line
                    const {data: data2, error: error2} = await supabase.from('transactions')
                    .update({active, end_time,percentage: percent_.percentage,days_outline: JSON.stringify(daysOutline)})
                    .eq('id', transactionID)
                    .select()

                    if(error2) {
                        alert('an error occured from while setting user active transaction');
                        console.log(error2)
                        setIsLoading(false);
                        return
                    }

                    if(data2) {
                        setIsLoading(false);
                        setSeverity('success');
                        setToastMsg('Transaction updated')
                        setOpen(true);
                        setTransaction(data[0])
                    }

            }else {
                    setIsLoading(false);
                    setSeverity('success');
                    setToastMsg('Transaction updated')
                    setOpen(true);
                    setTransaction(data[0])
                }
            }
        }


        return (
            <div>
                <Header />
                <Spacebox padding="20px" />
                <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
                <Container className="border20 space-box-20 dark-theme">
                    <Typography variant="h3" className="bold">
                        Approve Transaction
                    </Typography>
                    <Typography variant="body2">
                        Enter ID of transaction you want to approve
                    </Typography>
                    <Spacebox padding="10px" />
                    <div className="mid margin-auto">
                        <input placeholder="Transaction ID here..." value={transactionID} onChange={(e) => setTransactionId(e.target.value)} />
                        <Spacebox padding="5px" />
                        <CustomButton className="fullwidth" backgroundColor="var(--primary)" color="white" padding="10px" borderRadius="10px" handleClick={() => getTransaction()}>
                            Get Transaction
                        </CustomButton>
                        <Spacebox padding='10px' />
                        {isLoading && <LinearProgress />}
                    </div>
                    {transaction && <table>
                        <thead>
                            <tr className="table-head">
                                <td>ID</td>
                                <td>Date</td>
                                <td>Type</td>
                                <td>Amount</td>
                                <td>Status</td>
                                <td>Actions</td>
                            </tr>
                        </thead>
                        {transaction &&
                            <tbody className="hide-scrollbar" style={{ height: '100%', overflowY: 'hidden' }}>
                                <tr className="data-row">
                                    <td>{transaction.id}</td>
                                    <td>{convertToDate(transaction.timestamp)}</td>
                                    <td>{transaction.deposit ? 'Deposit' : 'Withdraw'}</td>
                                    <td>{transaction.amount}</td>
                                    <td>{styleStatus(transaction.status)}</td>
                                    <td>
                                        <IconButton onClick={() => changeStatus("pending")}>
                                            <Autorenew sx={{ color: '#24f4ff', width: 18, height: 18 }} />
                                        </IconButton>
                                        <IconButton onClick={() => changeStatus("successful")}>
                                            <CheckOutlined sx={{ color: '#3ECF8E', width: 18, height: 18 }} />
                                        </IconButton>
                                        <IconButton onClick={() => changeStatus("failed")}>
                                            <Cancel sx={{ color: '#ff0000', width: 18, height: 18 }} />
                                        </IconButton>
                                    </td>
                                </tr>
                            </tbody>
                        }
                    </table>}
                </Container>
            </div>
        );
    }

    export default Approve;